<template>
  <div class="received-payment-gateway-balance" :class="[{'p-2': paymentGatewayId === 0}]">

    <div class="received-payment-gateway-balance-inputs">
      <div class="received-payment-gateway-balance-fields">
        <custom-validate-input v-model="receivedBalanceTransferId"
                               :classes="{'w-full': true}"
                               :regex="$validator('regex.treasury.paymentGateway.transferId')"
                               :rtl="false"
                               :label="`${$t('treasury.paymentGateways.receive.labels.receivedBalanceTransferId')}`"/>
      </div>
      <div class="received-payment-gateway-balance-fields">
        <custom-price-input v-model="receivedBalance"
                            :classes="{'w-full': true}"
                            :is-empty="false"
                            :label="`${$t('treasury.paymentGateways.receive.labels.receivedBalance')}`"/>
      </div>
      <div class="received-payment-gateway-balance-fields">
        <custom-price-input v-model="wage"
                            :classes="{'w-full': true}"
                            :label="`${$t('treasury.paymentGateways.receive.labels.wage')}`"/>
      </div>
      <div class="received-payment-gateway-balance-fields flex justify-between items-center mb-3">
        <div class="field-label">
          {{ $t('treasury.paymentGateways.receive.labels.receiveBalanceWithWage') }}
        </div>
        <custom-price-input :value="{value: getReceiveBalanceWithWage, isValid: true}"
                            class="my-0"
                            :classes="{'w-full': true}"
                            :disabled="true"/>
      </div>
    </div>

    <div class="received-payment-gateway-balance-details">

      <div class="received-payment-gateway-balance-fields">
        <custom-price-input :disabled="true"
                            :value="receivedYesterdayBalance"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :label="`${$t('treasury.paymentGateways.receive.labels.receivedYesterdayBalance')}`"/>
      </div>
      <div class="received-payment-gateway-balance-fields">
        <custom-price-input :disabled="true"
                            :value="totalBalance"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :label="`${$t('treasury.paymentGateways.receive.labels.totalBalance')}`"/>
      </div>
    </div>

    <button v-show="false"
            id="receivePaymentGatewayBalanceBTN"
            @click="receiveBalance"/>
  </div>
</template>

<script>
import CustomPriceInput from '../../../../../../components/customInput/customPriceInput'
import {getPaymentGatewayBalance, receivePaymentGatewayBalance} from '../../../../../../http/requests/treasury/paymentGateways'
import CustomValidateInput from '../../../../../../components/customInput/customValidateInput'
import {customParseFloat} from "@/assets/js/functions";

export default {
  name: 'receivedPaymentGatewayBalance',
  components: {CustomValidateInput, CustomPriceInput},
  metaInfo () {
    return {
      title: this.$t('treasury.paymentGateways.receive.title')
    }
  },
  props: {
    paymentGatewayId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      totalBalance: {
        value: '',
        isValid: true
      },
      receivedYesterdayBalance: {
        value: '',
        isValid: true
      },
      receivedBalance: {
        value: '',
        isValid: true
      },
      wage: {
        value: '',
        isValid: true
      },
      receivedBalanceTransferId: {
        value: '',
        isValid: true
      },
      actions: [
        {
          toolbar: [
            {
              id: 'receivePaymentGatewayBalanceBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success',
              permission: 'payment_gateway.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'receivePaymentGateway' ? 'paymentGateway' : 'paymentGateways'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  computed: {
    getReceiveBalanceWithWage () {
      return customParseFloat((customParseFloat(this.receivedBalance.value || '0') + customParseFloat(this.wage.value || '0')))
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
    }, 50)
    this.$store.dispatch('setPageTitle', this.$t('treasury.paymentGateways.receive.title'))
    this.getBalance()
  },
  methods: {
    customParseFloat,
    getBalance () {
      getPaymentGatewayBalance(this.paymentGatewayId).then(response => {
        this.totalBalance.value = response.data.data.total_balance
        this.receivedYesterdayBalance.value = response.data.data.payable_balance
        // this.receivedBalance.value = response.data.data.yesterday_balance
      })
    },
    receiveBalance () {
      if (!this.receivedBalance.isValid || !this.receivedBalanceTransferId.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.paymentGateways.receive.notifications.incorrectPriceOrId'),
          color: 'danger',
          time: 2400,
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })

        return false
      }
      const receive = {
        price: this.receivedBalance.value.toString().replaceAll(',', ''),
        wage: this.wage.value,
        transaction_reference_id: this.receivedBalanceTransferId.value
      }

      if (this.getReceiveBalanceWithWage > parseInt(this.receivedYesterdayBalance.value || '0')) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.paymentGateways.receive.notifications.balanceIsNotEnough'),
          color: 'danger',
          time: 2400,
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      receivePaymentGatewayBalance(this.paymentGatewayId, receive).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.paymentGateways.receive.notifications.receive.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changePaymentGateway')
      }).catch(error => {

        switch (error.response.status) {
        case 400:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.receive.notifications.balanceIsNotEnough'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break

        case 422:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.receive.notifications.incorrectPriceOrId'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break

        default:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.receive.notifications.receive.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.received-payment-gateway-balance {
  background-color: #fff;
  height: 100%;

  .received-payment-gateway-balance-inputs,
  .received-payment-gateway-balance-details {
    max-width: 400px;
  }

  .received-payment-gateway-balance-inputs {
    background: #cecece22;
    border-radius: .5rem;
    border: 1px solid #cecece;
    padding: 0 10px;

    .received-payment-gateway-balance-fields {

      .input-label {
        backdrop-filter: sepia(1);
        background: linear-gradient(180deg, #f8f8f8, #ffffff);
      }
    }
  }

  .received-payment-gateway-balance-fields {
    margin-top: 10px;
  }
}
</style>
